import * as React from 'react';
import farms from '../images/farms.jpg';
import ApiEntry from '../components/ApiEntry';
import apis from '../constants/apis';

const sampleHeader = {
  apikey: 'API_KEY',
};

const DocsV2 = () => {
  const renderApis = () => {
    return apis.v2.map((api, index) => <ApiEntry key={index} api={api} />);
  };

  return (
    <div
        className='overflow-y-scroll'
        style={{
          backgroundImage: `url(${farms})`,
          backgroundSize: '100%',
          height: '100%',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className='px3 pb3 max-width-5 mx-auto'>
          <div class='clearfix'>
            <div className='col-12'>
              <h2 className='py3'>Aquabyte API v2 Documentation</h2>
              <div className='mb3'>
                To use our API you must be issued an API key.
                <div className='mb2'>
                  <span className='bold'>Instructions: </span>For all HTTPS
                  requests you will need to pass in your API key as part of the
                  header:
                  <pre>
                    <code>{JSON.stringify(sampleHeader, null, 2)}</code>
                  </pre>
                </div>
                <div>
                  Our base url is <code>https://api.aquabyte.ai/v2/</code>
                </div>
                <div>Requests are limited to 1000 requests/hour</div>
              </div>
              {renderApis()}
            </div>
          </div>
        </div>
      </div>
  );
};
export default DocsV2;

import * as React from 'react';
import { Sticky } from 'react-sticky';

import logo from '../images/logo_full.png';
import { NavLink } from 'react-router-dom';

const Header = () => {
  return (
    <Sticky>
      {({ style }) => (
        <div
          className='z1 white border-bottom border-dark1 no-print flex'
          style={style}
        >
          <div className='flex max-width-5 col-12 mx-auto px3'>
            <div className='flex-auto'>
              <div className='flex items-center'>
                <img className='my1' src={logo} height={26} alt='logo' />
                <div className='ml1 big'>API</div>
              </div>
            </div>
            <div className='flex-none flex items-center'>
              <NavLink exact className='mx2 thicker hover-quiet' style={{color: '#456373'}} activeStyle={{color: '#213038'}} activeClassName='thickest' to='/v1'>v1 API</NavLink>
              <NavLink exact className='mx2 thicker hover-quiet' style={{color: '#456373'}} activeStyle={{color: '#213038'}} activeClassName='thickest' to='/v2'>v2 API</NavLink>
            </div>
          </div>
        </div>
      )}
    </Sticky>
  );
};
export default Header;

import React from 'react';
import { Routes, Route,BrowserRouter } from 'react-router-dom';
import './index.scss';
import Docs from './containers/Docs';
import DocsV2 from './containers/DocsV2';
import Layout from './components/Layout';

function App() { 
  return (
    <div className='App'>
      
      <BrowserRouter>
      <Layout>
      <Routes>
          <Route exact path='/' element={<Docs />} />          
          <Route path='/v1' element={<Docs />} />
          <Route path='/v2' element={<DocsV2 />} />
      </Routes>
      </Layout>
      </BrowserRouter>
      
    </div>
  );
}

export default App;

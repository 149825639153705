import * as React from 'react';
import { Label } from 'semantic-ui-react';

class ApiEntry extends React.Component {
  getColorFromHttpRequestType(requestType) {
    switch (requestType) {
      case 'GET':
        return 'blue';
      default:
        return null;
    }
  }
  renderParameters() {
    const { parameters } = this.props.api;
    if (!parameters) {
      return (
        <div>
          <span className='bold'>Parameters: </span>No parameters.
        </div>
      );
    }
    return (
      <div>
        <div className='bold'>Parameters: </div>
        {parameters.map((parameter, index) => (
          <div className='py-half' key={index}>
            <Label>
              <code>{parameter.name}</code>
            </Label>{' '}
            (<span className='bold'>{parameter.dataType}</span>,{' '}
            {parameter.parameterType}): {parameter.description}
          </div>
        ))}
      </div>
    );
  }

  render() {
    const { requestType, url, description, samplePayload, returnCode } =
      this.props.api;

    return (
      <div className='p3 mb2 white rounded'>
        <div className='flex items-center mb2'>
          <Label color={this.getColorFromHttpRequestType(requestType)}>
            {requestType}
          </Label>
          <div className='ml1 bold huge'>{url}</div>
        </div>
        {!url.includes('currentWeightDist') && this.renderParameters()}
        <div>
          <span className='bold'>Description: </span>
          {!url.includes('currentWeightDist') ? description : <b style={{color: 'red'}}>{description}</b>}

          {/* {description} */}
        </div>
        {!url.includes('currentWeightDist') && (
          <div>
            <span className='bold'>Return code: </span>
            {returnCode}
          </div>
        )}
        {!url.includes('currentWeightDist') && (
          <>
            <span className='bold'>Sample Payload:</span>
            <pre style={{ overflow: 'auto' }}>
              <code>{JSON.stringify(samplePayload, null, 2)}</code>
            </pre>
          </>
        )}
      </div>
    );
  }
}

export default ApiEntry;

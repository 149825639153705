const apis = {
  v1: [
    {
      requestType: 'GET',
      url: '/sites',
      description:
        'Returns a list of sites and pens available for the consumer of the API',
      samplePayload: [
        {
          id: '7a3e1e23-0955-434b-b7f0-070825d9f7dc',
          name: 'Aquabyte Site',
          governmentSiteNumber: 12345,
          pens: [
            {
              id: '07096382-55dc-4e53-83cf-28ba5c77e02a',
              name: 'Pen 1',
              siteId: '7a3e1e23-0955-434b-b7f0-070825d9f7dc',
            },
          ],
        },
      ],
      returnCode: 200,
    },
    {
      requestType: 'GET',
      url: '/sites/{siteId}',
      description: 'Fetch data about a site',
      parameters: [
        {
          name: 'siteId',
          parameterType: 'path',
          dataType: 'string',
          description: 'Id of the site',
        },
      ],
      samplePayload: {
        id: '7a3e1e23-0955-434b-b7f0-070825d9f7dc',
        name: 'Aquabyte Site',
        governmentSiteNumber: 12345,
        pens: [
          {
            id: '07096382-55dc-4e53-83cf-28ba5c77e02a',
            name: 'Pen 1',
            siteId: '7a3e1e23-0955-434b-b7f0-070825d9f7dc',
          },
        ],
      },
      returnCode: 200,
    },
    {
      requestType: 'GET',
      url: '/pens',
      description: 'List all pens available',
      samplePayload: [
        {
          id: '07096382-55dc-4e53-83cf-28ba5c77e02a',
          name: 'Pen 1',
          siteId: '7a3e1e23-0955-434b-b7f0-070825d9f7dc',
        },
      ],
      returnCode: 200,
    },
    {
      requestType: 'GET',
      url: '/pens/{penId}',
      description: 'Fetch info about a pen',
      parameters: [
        {
          name: 'penId',
          parameterType: 'path',
          dataType: 'string',
          description: 'Id of the pen',
        },
      ],
      samplePayload: {
        id: '07096382-55dc-4e53-83cf-28ba5c77e02a',
        name: 'Pen 1',
        siteId: '7a3e1e23-0955-434b-b7f0-070825d9f7dc',
      },
      returnCode: 200,
    },
    {
      requestType: 'GET',
      url: '/pens/{penId}/liceCount',
      description:
        'Get lice counts in a pen for a given time interval. If no dates supplied, returns last 7 days',
      parameters: [
        {
          name: 'penId',
          parameterType: 'path',
          dataType: 'string',
          description: 'Id of the pen',
        },
        {
          name: 'fromDate',
          parameterType: 'query',
          dataType: 'string',
          description: 'Date in the format of YYYY-MM-DD (ex: "2019-03-01")',
        },
        {
          name: 'toDate',
          parameterType: 'query',
          dataType: 'string',
          description: 'Date in the format of YYYY-MM-DD (ex: "2019-04-10")',
        },
        {
          name: 'movingAvg',
          parameterType: 'query',
          dataType: 'boolean',
          description:
            'Indicate if you want lice counts returned to be the moving average over the last period.',
        },
      ],
      samplePayload: [
        {
          date: '2019-09-20',
          femaleAvg: 0.0203389830508474,
          movingAvg: 0.0271186440677966,
          numberOfFishCounted: 295,
          scottishFemaleAvg: 0,
        },
      ],
      returnCode: 200,
    },
  ],
  v2: [
    {
      requestType: 'GET',
      url: '/environmental/{penId}',
      description:
        'Returns environmental data of a pen for a given time interval. Dates are optional, by default, data is fetched for the last seven days',
      parameters: [
        {
          name: 'penId',
          parameterType: 'path',
          dataType: 'string',
          description: 'ID of the pen',
        },
        {
          name: 'fromDate',
          parameterType: 'query',
          dataType: 'string',
          description: 'Date in the format of YYYY-MM-DD (ex: "2020-03-01")',
        },
        {
          name: 'toDate',
          parameterType: 'query',
          dataType: 'string',
          description: 'Date in the format of YYYY-MM-DD (ex: "2020-03-04")',
        },
      ],
      samplePayload: [
        {
          date: '2020-03-01',
          temperatureAvg: 10.0,
          cameradepthAvg: 10.0,
          cameradepthMin: 10.0,
          cameradepthMax: 20.0
        },
        {
          date: '2020-03-02',
          temperatureAvg: 11.0,
          cameradepthAvg: 10.1,
          cameradepthMin: 19.0,
          cameradepthMax: 20.0,
        },
        {
          date: '2020-03-03',
          temperatureAvg: 11.4,
          cameradepthAvg: 9.8,
          cameradepthMin: 10.0,
          cameradepthMax: 20.0
        },
        {
          date: '2020-03-04',
          temperatureAvg: 12.0,
          cameradepthAvg: 9.9,
          cameradepthMin: 1.0,
          cameradepthMax: 2.0
        },
      ],
      returnCode: 200,
    },
    {
      requestType: 'GET',
      url: '/sites',
      description: 'Returns a list of available sites and pens',
      samplePayload: [
        {
          id: 1,
          name: 'Aquabyte Site',
          governmentSiteNumber: 12345,
          pens: [
            {
              id: 2,
              name: 'Pen 1',
              siteId: 1,
              penNumber: 7,
            },
          ],
        },
      ],
      returnCode: 200,
    },
    {
      requestType: 'GET',
      url: '/sites/{siteId}',
      description: 'Returns data about a site',
      parameters: [
        {
          name: 'siteId',
          parameterType: 'path',
          dataType: 'string',
          description: 'ID of the site',
        },
      ],
      samplePayload: {
        id: 1,
        name: 'Aquabyte Site',
        governmentSiteNumber: 12345,
        pens: [
          {
            id: 2,
            name: 'Pen 1',
            siteId: 1,
            penNumber: 7,
          },
        ],
      },
      returnCode: 200,
    },
    {
      requestType: 'GET',
      url: '/pens',
      description: 'Returns a list of available pens',
      samplePayload: [
        {
          id: 2,
          name: 'Pen 1',
          siteId: 1,
          penNumber: 7,
        },
      ],
      returnCode: 200,
    },
    {
      requestType: 'GET',
      url: '/pens/{penId}',
      description: 'Returns data about a pen',
      parameters: [
        {
          name: 'penId',
          parameterType: 'path',
          dataType: 'string',
          description: 'ID of the pen',
        },
      ],
      samplePayload: {
        id: 2,
        name: 'Pen 1',
        siteId: 1,
        penNumber: 7,
      },
      returnCode: 200,
    },
    {
      requestType: 'GET',
      url: '/pens/{penId}/liceCount',
      description:
        'Get lice counts in a pen for a given time interval. If no dates supplied, returns last 7 days',
      parameters: [
        {
          name: 'penId',
          parameterType: 'path',
          dataType: 'string',
          description: 'Id of the pen',
        },
        {
          name: 'fromDate',
          parameterType: 'query',
          dataType: 'string',
          description: 'Date in the format of YYYY-MM-DD (ex: "2019-03-01")',
        },
        {
          name: 'toDate',
          parameterType: 'query',
          dataType: 'string',
          description: 'Date in the format of YYYY-MM-DD (ex: "2019-04-10")',
        },
        {
          name: 'movingAvg',
          parameterType: 'query',
          dataType: 'boolean',
          description:
            'Indicate if you want lice counts returned to be the moving average over the last period.',
        },
      ],
      samplePayload: [
        {
          date: '2019-09-20',
          femaleAvg: 0.0203389830508474,
          movingAvg: 0.0271186440677966,
          femaleAvgConverted: 0.0101694915254237,
          movingAvgConverted: 0.0013951713989863,
          numberOfFishCounted: 295,
          scottishFemaleAvg: 0,
        },
      ],
      returnCode: 200,
    },
    {
      requestType: 'GET',
      url: '/pens/{penId}/biomassData',
      description:
        'Returns biomass data of a pen for a given time interval. Dates are optional––by default, data is fetched for the last seven days',
      parameters: [
        {
          name: 'penId',
          parameterType: 'path',
          dataType: 'string',
          description: 'ID of the pen',
        },
        {
          name: 'fromDate',
          parameterType: 'query',
          dataType: 'string',
          description: 'Date in the format of YYYY-MM-DD (ex: "2020-03-01")',
        },
        {
          name: 'toDate',
          parameterType: 'query',
          dataType: 'string',
          description: 'Date in the format of YYYY-MM-DD (ex: "2020-04-01")',
        },
      ],
      samplePayload: [
        {
          penId: 2,
          date: '2020-03-01',
          numFish: 1000,
          kFactor: 1.15,
          certainty: 'medium',
          dailyNumFish: 100,
          // distributionConsistency: 0.85,
          avgWeight: 2170.82,
          cv: 0.35,
          weightDist: {
            interval: [0, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000],
            distribution: [
              0.003, 0.008, 0.063, 0.143, 0.287, 0.273, 0.176, 0.044, 0.001,
              0.002,
            ],
          },
        },
      ],
      returnCode: 200,
    },
    {
      requestType: 'GET',
      url: '/pens/{penId}/currentWeightDist',
      description:
        'Deprecated. Please use the biomassData API to retrieve WeightDist data.',
      // parameters: [
      //   // {
      //   //   name: 'penId',
      //   //   parameterType: 'path',
      //   //   dataType: 'string',
      //   //   description: 'ID of the pen',
      //   // },
      // ],
      // samplePayload: {
      //   // penId: 2,
      //   // date: '2020-07-08',
      //   // weightDist: [
      //   //   {
      //   //     bucketRange: '1000g-2000g',
      //   //     percentageFish: 0.3,
      //   //   },
      //   // ],
      // },
      returnCode: 200,
    },
    {
      requestType: 'GET',
      url: '/pens/{penId}/welfareData',
      description:
        'Returns welfare data of a pen for a given time interval. Dates are optional––by default, data is fetched for the last seven days',
      parameters: [
        {
          name: 'penId',
          parameterType: 'path',
          dataType: 'string',
          description: 'ID of the pen',
        },
        {
          name: 'fromDate',
          parameterType: 'query',
          dataType: 'string',
          description: 'Date in the format of YYYY-MM-DD (ex: "2023-09-18")',
        },
        {
          name: 'toDate',
          parameterType: 'query',
          dataType: 'string',
          description: 'Date in the format of YYYY-MM-DD (ex: "2023-09-20")',
        },
      ],
      samplePayload: [
        {
          reportDate: '2023-09-21',
          wiseScores: {
            bodyWound: {
              active: {
                1: 0,
                2: 0,
                3: 0,
              },
              healed: {
                1: 0.08,
                2: 0.04,
                3: 0,
              },
              nothing: 0.88,
              reportFrequency: 'daily',
              avgDailySamples: 25,
            },
            maturation: {
              active: {
                1: 0,
                2: 0,
                3: 0,
              },
              healed: {
                1: 0,
                2: 0,
                3: 0,
              },
              nothing: 1,
              reportFrequency: 'daily',
              avgDailySamples: 27,
            },
            scaleLoss: {
              active: {
                1: 0,
                2: 0,
                3: 0,
              },
              healed: {
                1: 0,
                2: 0,
                3: 0,
              },
              nothing: 1,
              reportFrequency: 'daily',
              avgDailySamples: 26,
            },
            snoutWound: {
              active: {
                1: 0.38,
                2: 0.04,
                3: 0,
              },
              healed: {
                1: 0,
                2: 0,
                3: 0,
              },
              nothing: 0.58,
              reportFrequency: 'daily',
              avgDailySamples: 26,
            },
          },
          penId: 530,
        },
      ],
      returnCode: 200,
    },
  ],
};

export default apis;

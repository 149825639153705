import * as React from 'react';
import { StickyContainer } from 'react-sticky';

import Header from './Header';

class Layout extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <StickyContainer style={{ height: '100%' }}>
        <div
          className='flex flex-column'
          style={{ height: '100vh', backgroundColor: '#FFFFFF' }}
        >
          <Header />
          <div className='flex-auto relative'>{children}</div>
        </div>
      </StickyContainer>
    );
  }
}

export default Layout;
